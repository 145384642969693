import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c("v-row", { attrs: { "justify-left": "" } }, [
        _c("div", { staticClass: "H4-Primary-Left" }, [_vm._v("Next Steps")])
      ]),
      _c("br"),
      _c("v-row", [_c("div", { staticClass: "Text-Style" }, [_vm._v("iOS")])]),
      _c("v-row", [
        _c("div", { staticClass: "body-text text-row" }, [
          _vm._v(
            "\n      Wait for your site administrator to contact you. They will provide you with a link and\n      download code to get the Bolter App on your device.\n    "
          )
        ])
      ]),
      _c("br"),
      _c("v-row", [
        _c("div", { staticClass: "Text-Style" }, [_vm._v("Android")])
      ]),
      _c("v-row", [
        _c("div", { staticClass: "body-text text-row" }, [
          _vm._v(
            "\n      Download the Bolter App from the Google Play Store and log in to get started.\n    "
          )
        ])
      ]),
      _c(VImg, {
        staticClass: "google-badge",
        attrs: {
          src: require("@/assets/google-play.svg"),
          width: 128,
          height: 49
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }