<template>
  <v-container>
    <v-row justify-left>
      <div class="H4-Primary-Left">Next Steps</div>
    </v-row>
    <br />
    <v-row>
      <div class="Text-Style">iOS</div>
    </v-row>
    <v-row>
      <div class="body-text text-row">
        Wait for your site administrator to contact you. They will provide you with a link and
        download code to get the Bolter App on your device.
      </div>
    </v-row>
    <br />
    <v-row>
      <div class="Text-Style">Android</div>
    </v-row>
    <v-row>
      <div class="body-text text-row">
        Download the Bolter App from the Google Play Store and log in to get started.
      </div>
    </v-row>
    <v-img
      :src="require('@/assets/google-play.svg')"
      :width="128"
      :height="49"
      class="google-badge"
    />
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.text-row {
  margin-top: 8px;
  width: 461px;
}

.google-badge {
  margin-left: -10px;
  margin-top: 20px;
}

.body-text {
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.25px;
  text-align: left;
}
</style>
